import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Table, TableHeader, TableBody,  } from 'semantic-ui-react';
import * as select from '../../steps/data/selectors';
import { processSchema } from '../../process/data/model';

const StepsListForPanelList = ({stepsObj, pathToLink, linkField = 'id', displayField}) => {
    return (
        <Table.Row>
            <Table.Cell style={{width: "90%"}}>
                <Link to={{pathname: pathToLink + (stepsObj[linkField].toLowerCase()), id:stepsObj.id}}>{stepsObj[displayField]}</Link>
            </Table.Cell>
        </Table.Row>
    )
}

const StepsListForLeftPanel = (props, {isEdit}) => {
    // let processName = props.processName === undefined ? "" : props.processName
    let dataId = props.id === undefined ? "" : props.id
    let data = props.data === undefined ? [] : props.data
    let pathToLink = props.pathToLink=== undefined ? "" : props.pathToLink
    let displayField = props.displayField
    let linkField = props.linkField
   
    return (
        <Container className="padding70">
            <Table selectable basic='very' style={{width: "100%"}}>
                <TableBody>
                    { Object.keys(data).map((key) => 
                    <StepsListForPanelList key = {key} displayField= {displayField} linkField = {linkField} pathToLink= {pathToLink} stepsObj={data[key]} /> )}
                </TableBody>
            </Table>
        </Container> 
    )
}

export default StepsListForLeftPanel;