import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {fetchWorkroom, deleteWorkroom, setNotifyDone, archiveWorkroom, restoreWorkroom, filterItems } from '../data/actions'
import * as select from '../data/selectors'
import { searchColumns } from '../data/model'
import { Container } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
import { TableList } from '../../../utilities/tableUtils'
import { Link } from 'react-router-dom';
   

const WorkroomList = (props) => {
    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 

    const dispatch = useDispatch();
    const workroom = useSelector(state => select.getWorkroomArray(state))
    const workroomFetched = useSelector(state => select.getIsWorkroomFetched(state, props))

    useEffect(() => {
        if(!workroomFetched){
            dispatch(fetchWorkroom());
        }
    },[workroomFetched]);


    useEffect(() => {
        dispatch (updateHeaderTitle ({'title':'Workrooms List'}))
    }, [])

    const archiveWorkroomFromList = (values) => {
        const obj = {id: values}
        dispatch(archiveWorkroom(obj))
    }
    const restoreWorkroomFromList = (values) => {
        const obj = {id: values}
        dispatch(restoreWorkroom(obj))
    }

    // Loading Logic if tasks not fetched
    return (
        <Container>
            {workroomFetched === false ? 
                <p>Loading ... </p> 
            : 
                <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}>
                    <div style={{height: (heights-20)+'px', width: "100%"}}>
                        <TableList columns={searchColumns} data={workroom} Actions={WorkroomActions} fn={{archiveWorkroomFromList, restoreWorkroomFromList}}/>
                    </div>
                </div>
            }
        </Container>
    )
}



const WorkroomActions = ({object: workroom, fn}) => {
    const isNegative = workroom.status === 1 ? false : true;
    return (
        <>
        {!isNegative ? 
            <>
            <Link to={{pathname: "/workroom/edit/"+workroom.id, workroomId:workroom.id, workroomName: workroom.workroomName, actionTypes: {"archiveWorkroom": archiveWorkroom, "restoreWorkroom": restoreWorkroom}}}>Edit</Link><span>&nbsp;&nbsp;</span>
            <span style={{cursor: 'pointer', color: 'red'}} onClick={() => fn.archiveWorkroomFromList(workroom.id)}>Archive</span><span>&nbsp;&nbsp;</span>
            </>
            :<>
            <span style={{cursor: 'pointer', color: 'green'}} onClick={() => fn.restoreWorkroomFromList(workroom.id)}>Restore</span><span>&nbsp;&nbsp;</span>
            </>
            }    
        </>            
    )
} 

export default WorkroomList;
