import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsUsersFetched } from './selectors'
import {savedUsers,fetchedUsers, storeSearchUsersResult, deletedUsers } from './actions'
import { handlError, parseError } from '../../app/serverError'
import apiContainer, { getHeaderJWT, URL } from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import axios from 'axios'

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

//const axios = apiContainer.axios;

//#region Saga for Create Users 

function* createUsers() {
    yield takeEvery('CREATE_USERS',saveUsers)
  }

function* saveUsers(action){
  try {
      const { response, error } = yield call(saveUsersAPI, action.payload)
      //console.log("Saga",action.payload)
      if (response) 
      
        yield put (savedUsers(action.payload))
      
      else {
        yield put (handlError(action, parseError(error)))
        sagaErrorMessage(error,action)  
      }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
}

function saveUsersAPI(data) {
    return axios.post(URL+'/user/create', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Users 

function* editUsers() {
  yield takeEvery('EDIT_USERS',saveEditedUsers)
}

function* saveEditedUsers(action){
  try {
      const { response, error } = yield call(editUsersAPI, action.payload)  
      if (response) 
      
        yield put (savedUsers(action.payload))
      
      else {
        yield put (handlError(action, parseError(error)))
        sagaErrorMessage(error,action)  
      }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
}

function editUsersAPI(data) {
  let user_id = data.id
  return axios.post(URL+'/user/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Users 

function* deleteUsers() {
  yield takeEvery('DELETE_USERS',saveDeleteUsers)
}

function* saveDeleteUsers(action){
  const { response, error } = yield call(deleteUsersAPI, action.payload)
  if (response) yield put (deletedUsers({id: action.payload.id, msg : response.data}))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteUsersAPI(data) {
  let usersId = data.id
  return axios.post(URL+'/delete'+ usersId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Users 

function* fetchUsers() {
  yield takeEvery('FETCH_USERS',requestUsers)
}
  

function* requestUsers(action){
  const isUsersFetched = yield select(getIsUsersFetched);
  if(!isUsersFetched){
    try {
        let { response, error } = yield call(requestUsersAPI)
        if (response) 
        
          yield put (fetchedUsers(response.data))
        
        else {
          yield put (handlError("FETCH_USER", parseError(error)))
          sagaErrorMessage(error,action)  
        }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    // yield put (fetchedUsers(JSON.parse(data)))
  }
}


function requestUsersAPI(data) {
  data = merge({}, data, userACL.atFetch())
  // axios.post('http://dev.localhost:8080/subscription/services/', {}, {crossDomain: true, withCredentials: true})
  return axios.get(URL+'/user/list', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Search Users 

function* searchUsers() {

  yield takeLatest('SEARCH_USERS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchUsersResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/users/search'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    //console.log(error)
    console.groupEnd()
}

export default function* usersSaga() {
    yield all([
        createUsers(),
        fetchUsers(),
        editUsers(),
        deleteUsers(),
        searchUsers()
    ])
  }  