import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getUsersList } from '../../users/data/selectors';
import { addTeamMember, removeTeamMember, setNotifyDone } from '../data/actions';
import { Form, Button, Grid } from 'semantic-ui-react';
import { Formik, Form as FormikForm } from 'formik';
import UsersSelect from '../../users/components/usersSelectTeamUsers' 

import { newTeamUser } from '../data/model'
import userACL from '../../../store/access'
import Notification from '../../../utilities/notificationUtils'
import { keyExists } from '../../../utilities/arrayUtils'
import { chain } from 'lodash';
import { BasicTableList } from '../../../utilities/tableUtils'
import v4 from 'uuid';

const TeamMember = (props) => {
    const users = useSelector(state => getUsersList(state))
    const displayMembers = chain(Object.values(props.members)).map(m => {
                                m.userName = users[m.userId] ? users[m.userId].name : null
                                return m
                            }).sortBy((item)=> item.userName).value()
    const usersInTeam = Object.values(props.members).map(m => m.userId)
    
    // const teamFetched = useSelector(state=> getIsTeamFetched(state, props))
    const team = props.team
    const dispatch = useDispatch()

    const removeUserFromTeam = (member) => {
        dispatch(removeTeamMember(member))
    }

    const saveTeam = (values, resetForm) => {
            userACL.atCreate(values)
            console.log(team)
            values.id = v4()
            values.teamId = team.id
            dispatch(addTeamMember(values))
            resetForm()
            // setSavedTeamId(values.id)
    }
  
    return (
        <>
            <div >
            <br/>
            <br/>
            <h3>Add a New Member</h3>
            <Grid >
                <Grid.Column style={{width: "80%"}}>
                <Formik id="addMemberForm" className ="CustomeForm"
                        initialValues={newTeamUser ()}
                        // validationSchema={teamSchema}
                        onSubmit={(values, { resetForm }) => saveTeam(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} onSubmit={handleSubmit} >
                                 <Form.Group widths={3}>
                                        <UsersSelect name="userId" isLabel="false" excludeUsers={usersInTeam}/>
                                        <Button type="submit" floated="left" color="blue" size="small" > Add </Button>
                                </Form.Group>
                                        {/* {savedTeamId ?
                                            <Notification id={savedTeamId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                            : 
                                        null} */}
                            </Form>
                        )}
                    />
                </Grid.Column >
                </Grid>
                <br/>
                <br/>
                </div>
                <h3>Current Members - {displayMembers.length}</h3>
                {/* <div style={{height: (heights-400)+"px", width : '95%', overflowY: "auto"}}> */}
                <div style={{height: "40vh", width : '95%', overflowY: "auto"}}>
                <BasicTableList columns={[{Header: 'User',accessor: 'userName'}]} data={displayMembers} Actions={RemoveMemberActions} fn={{removeUserFromTeam}}/>
            </div>

        </>        
    )
} 

const RemoveMemberActions = ({object: member, fn}) => {
    return (
        <>
            <span style={{cursor: 'pointer', color: 'red'}} onClick={() => fn.removeUserFromTeam(member)}>Remove</span><span>&nbsp;&nbsp;</span>
        </>            
    )
} 

export default TeamMember;