import { createSelector } from 'reselect'
import { startsWith, cloneDeep, orderBy, difference } from 'lodash';
import { users } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'

export const getIsFetchingUsers = (state, props) => state.users.params.isFetching;
export const getIsUsersFetched = (state, props) => state.users.params.usersFetched;
export const getUsersList = (state, prop) => state.users.byId
export const getIsSearching = (state, props) => state.users.params.isSearching;
export const getIsSearchFetched = (state, props) => state.users.params.searchFetched;
export const getSearchResults = (state, prop) => state.users.searchResults
export const filters = (state, prop) =>  state.users.filterResults ;
export const getDeleteStatus = (state, prop) => state.users.deleteStatus
export const getDelFetched = (state, prop) => state.users.params.isDelFetched
export const getNotification = (state, id) => state.users.notifications[id]

export const getUsers = (state, props) => {
    if(props.match.path==='/users/create'){
        return users()
    }
    if(props.match.path==='/users/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.users.byId[_id]);
        return obj        
    }
}




export const getUserNameFromId = (state, userid) => {
    if ( !userid) return ''
    let obj = state.users.byId
    
    let user = obj[userid]
    if (!user) return undefined
    return user.name
}


export const getUsersParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/users/create')){ 
        params.title = state.users.params.createTitle
        params.submitButtonText = state.users.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/users/edit/')){ 
        params.title = state.users.params.editTitle
        params.submitButtonText = state.users.params.editSubmitButtonText        
    };

    return params
}


export const getListOfUsersFromTeamUserList = (state, userList) => {
    if (userList == undefined )
        return []
    let usersarray = state.users.byId
   
    let newobj = userList.map(teamuser => {
            return usersarray[teamuser.userId]
    })
    return newobj
    // let teamid = obj.teamid
    // let teamusers = getListOfUsersFromTeam (state, teamid)
    // let userid, username

        // return teamusers
}


export const getTeamsLedByUser = (state, userid) => {
    if (userid == undefined )
        return undefined
    let teamarray = state.team.byId
    if (Object.keys(teamarray).length == 0) return

    let teamLed = Object.values(teamarray).filter ((obj) => {
        if (obj.teamLead == userid)
            return obj
    })
    
    // if (teamLed.length > 0)
        return teamLed
    
    return null
}



export const selectUsers = createSelector(
    getUsersList,
    users => { 
        const keys = Object.keys(users)
        const obj = keys.map( (key) => { return { value : key, text : users[key].name, className: "selectLiCss" }})
        const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
        return sortedOptions
    })


export const selectUsersExcluding = (state, excludeList) => { 
        const users = getUsersList(state)
        const keys = difference(Object.keys(users), excludeList)
        const obj = keys.map( (key) => { return { value : key, text : users[key].name, className: "selectLiCss" }})
        const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
        return sortedOptions
    }


export const selectUsersFromList = (userList) => {
    // console.log(' userLIst is', userList)
        if (userList == undefined) return []
  
        const keys = Object.keys(userList)
        const obj = keys.map( (key) => { return { value : userList[key].id, text : userList[key].name, className: "selectLiCss" }})
        const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
        return sortedOptions
    }


export const selectUsersSearched = createSelector(
    getSearchResults,
    users => { 
        const keys = Object.keys(users)
        const obj = keys.map( (key) => { return { key : key, value : key, text : users[key].name }})
        return obj
    })


export const getSortedUserList = createSelector( getUsersList, users => {
    let obj = Object.values (users)
    let sortedobj = orderBy(obj, [(item)=> item.name], ['asc']);
    return sortedobj
})

