import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as select from '../data/selectors'
import { Container } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { getTeamIdFromWorkroom} from '../../workRoom/data/selectors'
import {getJobsAndActiveTasksByTeamIdAndWorkroomId } from '../../team/data/selectors'
import { fetchJobsByWorkroomId } from '../data/actions'
import { fetchCurrTaskByTeam } from '../../tasks/data/actions'
import moment from 'moment'
import { JobTableList } from '../../../utilities/tableUtils'



const JobsListByWorkroom = (props) => {
    const workroomId = props.workroomId
    const jobsFetched = useSelector(state => select.isJobsFetchedByWorkroomId(state, workroomId))

    const teamId = useSelector(state => getTeamIdFromWorkroom(state, workroomId))
    const jobsReport = useSelector(state => getJobsAndActiveTasksByTeamIdAndWorkroomId(state, teamId, workroomId))

    const dispatch = useDispatch();

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 

    useEffect(()=>{
    if (!jobsFetched)
        dispatch(fetchJobsByWorkroomId({'workroomId': workroomId}));
        dispatch(fetchCurrTaskByTeam({ 'teamId': teamId }));
    }, [workroomId]);      
    
    
    // useEffect(() => {
    //     if (!jobsReport)
    //         dispatch(fetchCurrTaskByTeam({ 'teamId': teamId }));
    // }, [teamId]);

    // Loading Logic if tasks not fetched
    if (jobsReport && jobsReport.length == 0 ){
        return <p> No records</p>
    }  else {
        return (
            <Container>
            {!jobsReport ? 
                <p> No records</p> 
            : 
            <>
            <JobTableList columns={searchColumns} data={jobsReport} />    
            </>
            }
        </Container>
        )
    }
}



export const searchColumns = [
    {
      Header: 'Sl No',
      accessor: 'Sl No',
    },
    {
      Header: 'Status',
      accessor: 'taskStatus',
    },
    {
      Header: 'Job',
      accessor: 'job',
      width: 150
    },
    {
      Header: 'Task Name',
      accessor: 'taskName', 
    },
    {
        Header: 'Current User',
        accessor: 'currentUser', 
    },
    {
      Header: () => (<div style={{textAlign:"right"}}>Priority</div>),
      accessor: 'priority',
      Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>
    },
    {
      Header: 'Deadline',
      accessor: 'deadline', 
      Cell : (props)=>{
        const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
        return <span>{custom_date}</span>
        }    },
    {
      Header: 'Date Start',
      accessor: 'dtStart', 
      Cell : (props)=>{
        const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
        return <span>{custom_date}</span>
        }
    },
    {
      Header: 'Job Owner',
      accessor: 'jobOwner', 
    },
  ]


export default JobsListByWorkroom;
