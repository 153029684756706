import React, { useEffect, useState }from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getTeam, getNotification, getTeamParams, getIsTeamFetched } from '../data/selectors';
import { createTeam, editTeam, setNotifyDone } from '../data/actions';
import { teamSchema } from '../data/model'
import { Container, Form, Button, Grid, } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import { updateHeaderTitle } from '../../app/data/actions'
import { newTeamUser } from '../data/model'
import { heightSet } from '../../../utilities/heightForListing'
import { keyExists } from '../../../utilities/arrayUtils'
import UsersSelect from '../../users/components/usersSelect' 
import TeamMember from './teamFormMembers'
import userACL from '../../../store/access'
import Notification from '../../../utilities/notificationUtils'

const TeamFormEdit = (props) => {
    const [heights, setHeight] = useState('')
    const [savedTeamId, setSavedTeamId] = useState(false)
    const [listOfUsersSelected, setListOfUsersSelected] = useState([])
    
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    
    const teamFetched = useSelector(state=> getIsTeamFetched(state, props))
    const team = useSelector(state => getTeam(state, props))
    const params = useSelector(state => getTeamParams(state, props))
    const dispatch = useDispatch()
    let teamName = "";
    
    if (props.match.path === '/team/edit/:id') {
        teamName = ( team === undefined ? "" : team.teamName )
    }

    useEffect(() => {
            dispatch(updateHeaderTitle({ 'title': 'Team: ' + teamName }))
    }, [teamName])


    // useEffect(() => {
    //     if (team.teamUsers.length == 0) {
    //         let vd = newTeamUser ()
    //         vd.teamId = team.id
    //        }
    //   })


    const editTeamUserObj =(values) => {
        let newteamusers = values.teamUsers
        let oldteamusers = team.teamUsers
        let oldteamusers_ids = oldteamusers.map ( tuser => tuser.id)
        newteamusers.map ((tuser) =>{
            if (keyExists(tuser.id, oldteamusers_ids)) {
                console.log (' Updating id :', tuser.id, tuser.userId)
                userACL.atUpdate ( tuser )
            } else 
            {
                console.log (' Inserting id :', tuser.id)
                userACL.atCreate ( tuser )
            }
        })
        values.teamUsers = newteamusers
    }


    const saveTeam = (values, resetForm) => {
        userACL.atUpdate(values)
        editTeamUserObj ( values )
        console.log (' !!!!Editing team with values ', values)
        dispatch(editTeam(values))
        setSavedTeamId(values.id)
    }
  
    return (
        <Container >
            <Grid  >
                <Grid.Column  className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
                    <Formik id="teamForm" size="large" 
                        initialValues={team}
                        validationSchema={teamSchema}
                        onSubmit={(values, { resetForm }) => saveTeam(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} className ="CustomeForm" onSubmit={handleSubmit}>
                                 <Form.Group widths={3}>
                                    <Field name="teamName" label = 'Team Name' component={FormikInputComponent}></Field>
                                    <UsersSelect style={{width: "50%"}} required={true} name="teamLead" label="Select Team Lead"/>
                                    <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                                        {savedTeamId ?
                                            <Notification id={savedTeamId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                            : 
                                        null}
                                </Form.Group>
                            </Form>
                        )}
                    />
                <TeamMember members={team.teamUsers} heights={heights} team={team}/>
                </Grid.Column>  
            </Grid>
        </Container>
    )
}

export default TeamFormEdit;