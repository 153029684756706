import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {fetchTeam,archiveTeam,restoreTeam } from '../data/actions'
import * as select from '../data/selectors'
import { searchColumns } from '../data/model'
import { Container } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
import { TableList } from '../../../utilities/tableUtils'
import { Link } from 'react-router-dom';


const TeamList = (props) => {

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')

    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 


    const dispatch = useDispatch();   
    const team = useSelector(state => select.getTeamArray(state))
    const teamFetched = useSelector(state => select.getIsTeamFetched(state, props));
    const archiveTeamFromList = (values) =>{
        const obj = {id: values}
        dispatch(archiveTeam(obj))
    }
    const restoreTeamFromList = (values) =>{
        const obj = {id: values}
        dispatch(restoreTeam(obj))
    }
    const TeamActions = ({object: team}) => {
        const teamStatus = team.status == 1 ? true : false
        return (
            <>
            {
            teamStatus ?
            <>

            <Link to={"/team/edit/"+team.id}>Edit</Link><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span style={{cursor: 'pointer', color: 'red'}} onClick={() => archiveTeamFromList(team.id)}>Archive</span><span>&nbsp;&nbsp;</span>
            </>
            :
            <>
            <span style={{cursor: 'pointer', color: 'green'}} onClick={() => restoreTeamFromList(team.id)}>Restore</span><span>&nbsp;&nbsp;</span>
            </>
            }
            </>
        )
    } 

    useEffect(() => {
        if(!teamFetched){
            dispatch(fetchTeam());
        }
    });

    useEffect(() => {
        dispatch (updateHeaderTitle ({'title':'Team List'}))
    }, [])

    return (
        <Container>
            {teamFetched === false ? 
                <p>Loading ... </p> 
            : 
                <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}>
                    <div style={{height: (heights-20)+'px', width: "100%"}}>
                        <TableList columns={searchColumns} data={team} Actions={TeamActions} />
                    </div>
                </div>
            }
        </Container>
    )
}

export default TeamList;
