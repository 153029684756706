import React, { useEffect, useState }from 'react';
import { Link, Redirect} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getProcess, getProcessFromId, getProcessParams, getIsProcessFetched } from '../data/selectors';
import {getActivityByProcessId} from '../../activities/data/selectors'
import { createProcess, editProcess, setNotifyDone } from '../data/actions';
import { updateHeaderTitle } from '../../app/data/actions'
import { processSchema, duplicateCheckProcessName } from '../data/model'
import { fetchActivityByProcess } from '../../activities/data/actions'
import {fetchProcessfileByProcessKey} from '../../processfile/data/actions'
import {getProcessFileByProcessKey} from '../../processfile/data/selectors'
import { Container, Input, Header, Table, TableHeader, TableBody, Form, Button, Grid, Accordion } from 'semantic-ui-react';
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'
import ProcessRightMenu from './processRightMenu'
import ProcessFormEdit from './processFormEdit'
import ProcessFileForm from '../../processfile/components/processfileForm'
import ActivityList from '../../activities/components/activityList'
import ActivityForm from '../../activities/components/activityForm'
import ProcessSummary from './processSummary'
import ProcessfileDisplay from '../../processfile/components/processfileDisplay'


const ProcessDispContainer = (props) => {
    const [heights, setHeight] = useState('')
    const [savedProcessId, setSavedProcessId] = useState(false)
    
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    
    // const processFetched = useSelector(state=> getIsProcessFetched(state, props))
    // const processFetched = useSelector(state => select.getIsProcessFetched(state, props))

    const dispatch = useDispatch()
    let processName = "";
    let processId = "";

    processId = props.location.processId ? props.location.processId : (props.location.processId ? props.location.processId :"")

    const process = useSelector(state => getProcessFromId(state, processId))
    const params = useSelector(state => getProcessParams(state, props))
    const activitiesData = useSelector(state => getActivityByProcessId(state, processId))
    const processfileList = useSelector(state => getProcessFileByProcessKey(state, process.processKey))
    processName = process === undefined ? "" : process.processName


    useEffect(() => {
        // console.log ('!!!!!!! Process is !!!!!', process)
        if (activitiesData === undefined ) {
            dispatch (fetchActivityByProcess ( {'processId' : processId}))
        }
        if (processfileList === undefined ) {
            dispatch (fetchProcessfileByProcessKey ( {'processKey' : process.processKey}))
        }
        dispatch (updateHeaderTitle ({'title':processName}))
    }, []) 


    useEffect(() => {
        // console.log ('!!!!!!! ProcessName is !!!!!', processName)
        if (activitiesData === undefined ) {
            dispatch (fetchActivityByProcess ( {'processId' : processId}))
        }
        if (processfileList === undefined ) {
            dispatch (fetchProcessfileByProcessKey ( {'processKey' : process.processKey}))
        }
        if (processName !== undefined && processName !== '') {
            dispatch (updateHeaderTitle ({'title':processName}))
        }
    }, [processId])

    const getChildProcessComponent = () =>
    {
        let path = props.match.path
        // console.log (' In display container path is ', path)
        if (path === '/process/edit/:id') {
            return <ProcessFormEdit {...props} />
        }
        if (path === '/process/processfile/create') {
            return <ProcessFileForm {...props} />
        }
        if (path === '/process/activity/list') {
            return <ActivityList {...props} />
        }
        if (path === '/process/activity/:id') {
            return <ActivityForm {...props} />
        }
        if (path === '/process/summary') {
            return <ProcessSummary {...props} />
        }
        if (path === '/process/processfile/:id') {
            return <ProcessfileDisplay {...props} />
        }
    }
    
    return (
        <Container >
            <Grid columns={8} divided columns='equal'>
                <Grid.Column style={{height: (heights)+"px"}} className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
                   {getChildProcessComponent()}
                </Grid.Column>

               <ProcessRightMenu {...props}/>
               
            </Grid>
        </Container>
    )
}

export default ProcessDispContainer;