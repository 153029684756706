import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "semantic-ui-react";
import * as select from "../data/selectors";
import { TableList } from "../../../utilities/tableUtils";
import { searchColumns } from "../data/model";
import { fetchRole } from "../data/action";
import { updateHeaderTitle } from "../../app/data/actions";

function RoleList(props) {
  const dispatch = useDispatch();
  const role = useSelector((state) => select.getRoleArray(state));

  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "Role List" }));
  }, []);

 

  const RoleActions = ({ object: role }) => {
    return (
      <>
        <Link to={"/roles/edit/" + role.id}>Edit</Link>
      </>
    );
  };

  return (
    <Container>
      <TableList
        columns={searchColumns}
        data={role}
        Actions={RoleActions}
      ></TableList>
    </Container>
  );
}

export default RoleList;
