import React from "react";
import { useDispatch } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell,
  TableRow,
  TableCell,
  Input,
  Icon,
  Button,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getJobPathLink } from "./pathUtils";
import { getIconColour, getIconName } from "./iconUtils";
import { copyToClipboardTable, copyToClipboardCell } from "./listUtils";
import CommentsDisplayWorkroom from "../modules/comments/components/commentsDisplayWorkroom";
import PopUp from "./popUp";

export const TableList = ({ columns, data, Actions, fn }) => {
  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, defaultColumn }, useFilters);
  const dispatch = useDispatch();

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <>
        <Input
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder={`Search ${count} records...`}
        />
      </>
    );
  }
  console.log("Actions", Actions);
  return (
    <Table className="padding55" basic="very" selectable>
      {headerGroups.map((headerGroup) => (
        <TableHeader {...headerGroup.getHeaderGroupProps()}>
          <Table.Row>
            <TableHeaderCell>Sl No</TableHeaderCell>
            {headerGroup.headers.map((column) => (
              <TableHeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
                <span>{column.canFilter ? column.render("Filter") : null}</span>
              </TableHeaderCell>
            ))}
            {Actions && <TableHeaderCell>Action</TableHeaderCell>}
          </Table.Row>
        </TableHeader>
      ))}
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              <TableCell>{parseInt(row.index) + 1}</TableCell>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              <TableCell>
                {Actions ? (
                  <Actions object={row.cells[0].row.original} fn={fn} />
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const BasicTableList = ({ columns, data, Actions, fn }) => {
  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, defaultColumn }, useFilters);
  const dispatch = useDispatch();

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <Input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  return (
    <Table basic="very" selectable collapsing>
      {headerGroups.map((headerGroup) => (
        <TableHeader {...headerGroup.getHeaderGroupProps()}>
          <Table.Row>
            <TableHeaderCell>Sl No</TableHeaderCell>
            {headerGroup.headers.map((column) => (
              <TableHeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
                <span>
                  {" "}
                  {column.canFilter ? column.render("Filter") : null}
                </span>
              </TableHeaderCell>
            ))}
            <TableHeaderCell>Action</TableHeaderCell>
          </Table.Row>
        </TableHeader>
      ))}
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              <TableCell>{parseInt(row.index) + 1}</TableCell>
              {row.cells.map((cell, index) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {" "}
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              <TableCell>
                {Actions ? (
                  <Actions object={row.cells[0].row.original} fn={fn} />
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const JobTableList = ({ columns, data, Actions, fn, HeaderLinks }) => {
  // const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter,}),[])
  console.log(data);
  const {
    getTableProps,
    getTableBodyProps,
    state,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data }, useFilters, useGlobalFilter);
  const dispatch = useDispatch();

  return (
    <Table basic="very" selectable>
      {headerGroups.map((headerGroup, index) => (
        <TableHeader {...headerGroup.getHeaderGroupProps()}>
          <Table.Row>
            <TableHeaderCell
              colSpan={visibleColumns.length - 1}
              style={{ textAlign: "left" }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                rows={rows}
              />
            </TableHeaderCell>
            <TableHeaderCell colSpan={1} style={{ textAlign: "left" }}>
              {HeaderLinks ? <HeaderLinks /> : null}
            </TableHeaderCell>
            <TableHeaderCell>{Actions ? "" : <></>}</TableHeaderCell>
          </Table.Row>
          <Table.Row key={index}>
            {headerGroup.headers.map((column, i) => (
              <TableHeaderCell key={i} {...column.getHeaderProps()}>
                {column.render("Header")}
              </TableHeaderCell>
            ))}
            {Actions && <TableHeaderCell>Action</TableHeaderCell>}
          </Table.Row>
        </TableHeader>
      ))}
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                let str = `${cell.row.original.jobKey}:${cell.row.original.jobName}`;
                if (
                  cell.column.Header === "Ticket" ||
                  cell.column.Header === "Job"
                )
                  return (
                    <>
                      <TableCell
                        {...cell.getCellProps()}
                        style={{ padding: "0.5em" }}
                      >
                        {/* <Icon
                          name="copy outline"
                          basic
                          size="small"
                          onClick={() => {
                            copyToClipboardCell(str);
                          }}
                        /> */}
                        <PopUp
                          msg={str}
                          basic
                          size={"small"}
                          onClick={copyToClipboardCell}
                          style={{ fontSize: "12px" }}
                        />
                        <Link
                          to={{ pathname: getJobPathLink(cell.row.original) }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      </TableCell>
                    </>
                  );
                else if (cell.column.Header === "Status")
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      style={{ padding: "0.5em" }}
                    >
                      <Icon
                        name={getIconName(cell.row.original.taskStatus)}
                        color={getIconColour(cell.row.original.taskStatus)}
                        size="large"
                      />
                    </TableCell>
                  );
                else if (cell.column.Header === "Job Status")
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      style={{ padding: "0.5em" }}
                    >
                      <Icon
                        name={getIconName(cell.row.original.jobStatus)}
                        color={getIconColour(cell.row.original.jobStatus)}
                        size="large"
                      />
                    </TableCell>
                  );
                else
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      style={{ padding: "0.5em" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
              })}
              <TableCell>
                {Actions ? (
                  <Actions object={row.cells[0].row.original} fn={fn} />
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  rows,
}) {
  const count = preGlobalFilteredRows.length;

  const jobKeys = rows.reduce((accum, row) => {
    let str = ` ${row.original.jobKey}: ${row.original.jobName}\n`;
    return (accum += str);
  }, "");

  console.log(rows);

  console.log(jobKeys);

  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      {/* <Icon
        // style={{ position: "absolute", right: "3%", top: "1.5%" }}
        name="copy outline"
        basic
        size="large"
        // color="green"
        onClick={() => {
          // copyToClipboardTable(jobKeys);
        }}
      /> */}
      <PopUp
        msg={` List of ${count} Job Keys`}
        basic
        size={"small"}
        onClick={copyToClipboardTable}
        style={{ fontSize: "15px" }}
      />{" "}
      Search:{" "}
      <Input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        transparent
        style={{ fontSize: "1.2rem", width: "90%" }}
      />
    </span>
  );
}
