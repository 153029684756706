import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {getInCompleteJobsByTeamIdforList}from '../../jobs/data/selectors'
import { Container, Table, TableHeader, TableBody, Header, Icon, Confirm} from 'semantic-ui-react'
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { getWorkroomNameFromId, getWorkroomShortNameFromId } from '../../workRoom/data/selectors'
import { deleteJobs, removePriority } from '../../jobs/data/actions'
import { getIconColour, getIconName } from '../../../utilities/iconUtils'
import moment from 'moment'
import { getUserNameFromId } from '../../users/data/selectors'
import PopupJobEdit from '../../jobs/components/popUpJobEdit'
import { get } from 'lodash';
import { JobTableList } from '../../../utilities/tableUtils'
    

const TeamJobsListForEdit = (props) => {
    const teamId = props.teamId
    const jobs = useSelector(state => getInCompleteJobsByTeamIdforList(state, teamId))
    const dispatch = useDispatch();

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 

    const deleteJobFromList = (job, setOpen) =>{
        console.log(' Deleting job ', job)
        dispatch(deleteJobs(job));
        setOpen (false)
    }

    // Loading Logic if tasks not fetched
    if (!jobs || jobs.length == 0 ) {
        return <p> No records </p>
    } else {
        return (
            <Container>
                <JobTableList columns={searchColumns({deleteJobFromList})} data={jobs}/>
            </Container>
        )
    }
}


const EditActions = ({object: job, fn}) => {
    const [open, setOpen] = useState(false)
    return (
            <>
            <PopupJobEdit job = {job}/>
            <span style={{cursor: 'pointer', color: 'red', cursor:'pointer'}} onClick={() => setOpen(true)}>Delete</span>            
            <Confirm
                inverted
                open={open}
                size='tiny'
                content ='Are you sure you want to delete this?'
                onCancel={() => setOpen(false)}
                onConfirm={() => fn.deleteJobFromList(job, setOpen)}
            />
            </>
    )
} 

export const searchColumns = (fn) => [
    {
        Header: 'Priority',
        accessor: 'priority',
    },
    {
        Header: 'Job Status',
        accessor: 'jobStatus',
    },
    {
        Header: 'Workroom',
        accessor: 'workroomName', 
    },
    {
        Header: 'Job',
        accessor: 'job',
        width: 150
    },
    {
        Header: 'Created By',
        accessor: 'createdBy', 
    },
    {
        Header: 'Date Created',
        accessor: 'dtCreate', 
        Cell : (props)=>{
            const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
            return <div style={{textAlign:'center'}}><span >{custom_date}</span></div>
            }
    },
    {
        Header: 'Action',
        Cell: (props) => {
            return <EditActions object={props.row.original} fn={fn} />
            }
    },
  ]

export default TeamJobsListForEdit;
