import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMenuTitle } from "../data/selectors";
import { Link, Redirect } from "react-router-dom";
import logo from "../../../images/realedge_horizontal_transparent.png";
import {
  Container,
  Dropdown,
  Menu,
  Grid,
  Image,
  Accordion,
  Icon,
  Header,
  Button,
} from "semantic-ui-react";
import PopUp from "../../../utilities/popUp";
import { copyToClipboard } from "../../../utilities/listUtils";

const MenuHeader = (props) => {
  // const dispatch = useDispatch()
  const menuTitle = useSelector((state) => getMenuTitle(state, props));

  const setHeaderTitle = () => {
    let title = menuTitle.title;
    let linkForWorkroom = menuTitle.linkForWorkroom;
    // console.log(linkForWorkroom);

    if (!linkForWorkroom) {
      return (
        <Header
          as="h2"
          className="marginLeft15 paddingTop20"
          id=""
          align="left"
        >
          {title}
        </Header>
      );
    } else {
      let linkAdd = menuTitle.linkAddress.split("#");
      console.log(linkAdd);
      let arr = title.split(linkForWorkroom);
      return (
        <Grid>
          <Grid.Row>
            <Header
              as="h2"
              className="marginLeft15 paddingTop20"
              id=""
              align="left"
              style={{ display: "flex" }}
            >
              {/* {arr.map((obj, i) => {
            if (i == arr.length - 1)
              <Link to={{ pathname: linkAdd }}>{linkForWorkroom}</Link>;
            else {
              obj;
            }
          })} */}

              <PopUp
                msg={menuTitle.title}
                style={{
                  marginLeft: "15px",
                  marginTop: "5px",
                  marginRight: "8px",
                  fontSize: "25px",
                }}
                size={"mini"}
                onClick={copyToClipboard}
              />
              <Link to={{ pathname: linkAdd[0], hash: linkAdd[1] }}>
                {linkForWorkroom}
              </Link>
              {arr[1]}
            </Header>
          </Grid.Row>
        </Grid>
      );
    }
  };

  const setHeaderName = (headerName) => {
    localStorage.setItem("Header", headerName);
  };

  return (
    <Grid.Row id="header1" className="padding0 boxBorderBottom">
      <Grid.Column
        id="menuCell"
        mobile={8}
        tablet={4}
        computer={4}
        style={{ height: "90px" }}
        className="boxBorderRight menuCell"
      >
        <Menu.Item className="imageClass marginLeft15">
          {/* <Link to="/"> */}
          <Image
            onClick={() => setHeaderName("Home")}
            src={logo}
            className="marginLeft15 logo"
          />
          {/* </Link> */}
        </Menu.Item>
      </Grid.Column>
      <Grid.Column
        id="containerCell"
        mobile={8}
        tablet={12}
        computer={12}
        className="paddingTop20 PaddingLeftZero paddingLeft0"
      >
        {setHeaderTitle()}
      </Grid.Column>
    </Grid.Row>
  );
};

export default MenuHeader;
