import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Container, Menu, Icon } from 'semantic-ui-react'
import JobsPriorityDndListByTeam from './jobsPriorityDndListByTeam'
import JobsPriorityListByTeam from './jobsPriorityListByTeam'
import 'react-toastify/dist/ReactToastify.css';
import viewStore from '../stream/toggleView'

const ViewMenu = () => {
    return (
        <span style={{ fontWeight: 'normal', cursor: 'pointer', color: '#4183C4'}} onClick={()=>viewStore.toggleView()}><Icon name='exchange'/>Toggle View</span>
    )
}


const JobsPriorityByTeam = (props) => {
    const teamId = props.teamId
    const [view, setView] = useState(()=>viewStore.getState())

    useLayoutEffect(() => {
        const subscription = viewStore.subscribe(setView)
        viewStore.init()
        return () => {
            console.log("subscription closed")
            subscription.unsubscribe()
        }
    }, []);

    return (
        <Container>
            {view.component == "dnd" ? <JobsPriorityDndListByTeam teamId={teamId} viewMenu={ViewMenu}/> : null}
            {view.component == "list" ? <JobsPriorityListByTeam teamId={teamId} viewMenu={ViewMenu}/> : null}
        </Container>
    )
}

export default JobsPriorityByTeam;
