import React, { useEffect, useState } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { fetchJobs, updateJobPriority } from '../data/actions'
import * as select from '../data/selectors'
import { Container, Table, TableHeader, TableHeaderCell, TableBody, Button, TableRow, TableCell, Ref, Icon, Message } from 'semantic-ui-react'
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { heightSet, widthSet, innerHeightSet,setMenuHeight, menuAccordionHeight } from '../../../utilities/heightForListing'
import { getClientNameFromWorkroom, getWorkroomShortNameFromId } from '../../workRoom/data/selectors'
import { fetchJobsByTeamId } from '../data/actions'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { getIconColour, getIconName } from '../../../utilities/iconUtils'
import { SimpleNotification } from '../../../utilities/notificationUtils'
import {cloneDeep } from 'lodash';
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import { getUserNameFromId } from '../../users/data/selectors'
import 'react-toastify/dist/ReactToastify.css';



const JobsTeam = ({ job, index, widths }) => {
    // const dispatch = useDispatch();
    // const isNegative = job.status === 1 ? false : true;
     const wname = useSelector(state => getWorkroomShortNameFromId(state,job.workroomId))
     const createdBy = useSelector(state => getUserNameFromId(state,job.uidCreate))

    // console.log('In job team')
    return (
        <Draggable key={job.id} draggableId={job.id} index={index}>
            {provided => (
                <Ref innerRef={provided.innerRef} >
                    <TableRow
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='borderBottomObj padding15'
                    >
                        <TableCell style={{ width: "5%" }} >
                            {job.priority}
                        </TableCell>
                        <TableCell style={{ width: "5%" }} >
                            <Icon name={getIconName(job.jobStatus)} color={getIconColour(job.jobStatus)} size='large' />
                        </TableCell>
                        <TableCell style={{ width: "10%" }} >
                            {wname}
                        </TableCell>
                        <TableCell style={{ width: "40%" }} >
                            {`${job.jobKey} - ${job.jobName}`}
                        </TableCell>
                        <TableCell style={{ width: "10%" }} >
                        {job.deadline ? moment(job.deadline).format('DD-MM-YYYY') : null}
                        </TableCell>
                        <TableCell style={{ width: "10%" }} >
                            {job.dtCreate ? moment(job.dtCreate).format('DD-MM-YYYY') : null }
                            {/* {new Date(job.dtCreate).toUTCString()} */}
                        </TableCell>
                        <TableCell style={{ width: "10%" }} >
                            {createdBy}
                        </TableCell>
                    </TableRow>
                </Ref>
            )}
        </Draggable>
    )
}

const JobsPriorityDndListByTeam = (props) => {
    const teamId = props.teamId
    const ViewMenu = props.viewMenu
    // const teamId = '359eab6c-6604-47d5-a858-3658e2bf85a3'
    const jobs = useSelector(state => select.getInCompleteJobsByTeamId(state, teamId))
    const jobsFetched = useSelector(state => select.isJobsFetchedByTeamId(state, teamId))
    const [listItems, setListItems] = useState([])
    const [savedSuccess, setSavedSuccess] = useState(false)

    const dispatch = useDispatch();

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')

    const redirectPath = '/team/view' + teamId

    useEffect(() => {
        setMenuHeight(setHeight)
        widthSet(setWidth)
        menuAccordionHeight(setInnerHeight)
        window.addEventListener('resize', function(){
            setMenuHeight(setHeight)
            widthSet(setWidth)
            menuAccordionHeight(setInnerHeight)
            // heightSetFotTaskForm(setInnerCommentHeight)
        }, false);
    }) 


    useEffect(() => {
        if (!jobsFetched)
            dispatch(fetchJobsByTeamId({ 'teamId': teamId }));
    }, [teamId]);



    useEffect(() => {
        if (jobs.length > 0) {
            setListItems(cloneDeep(jobs))
        }

    }, [jobsFetched, jobs.length]);


    const handleSubmit = () =>{
        
        // let newarray = listitems.filet
        let newarray = listItems.filter(function( obj ) {
            return obj.priority !== null;
        });

        console.log (' The new priority list is ', newarray)

        dispatch (updateJobPriority(newarray))
        setSavedSuccess (true)
        setTimeout(function(){ 
            setSavedSuccess (false)
        }, 2000);
    }

    const handleCancel = () =>{
        setListItems(cloneDeep(jobs))
        let pathR = '/team/view/' + teamId
        // setTimeout(function(){ 
            // props.history.push(pathR)    
        // }, 500);  
     
    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result

        if (!destination)
            return
        if ((destination.droppableId == source.droppableId) && (destination.index == source.index))
            return

        const items = Object.assign([], listItems);
        let curritem = listItems[source.index];
        let previtem = null;

        if (destination.index > 0) {
            if (destination.index < source.index)
                previtem = listItems[destination.index -1]
            else 
                previtem = listItems[destination.index]
        }
            
        if (previtem !=null )
            console.log(' Previtem is ', previtem.jobKey, previtem.priority)
        if (previtem == null)
            curritem.priority = 1
        else 
        {
            if (previtem.priority == undefined || previtem.priority == null)
                curritem.priority = null
            if (previtem.priority != undefined && previtem.priority != null && destination.index < source.index)
                curritem.priority = parseInt((previtem.priority + 1), 10)
                if (previtem.priority != undefined && previtem.priority != null && destination.index > source.index)
                curritem.priority = parseInt((previtem.priority - 1), 10)
        }

        items.splice(source.index, 1);
        items.splice(destination.index, 0, curritem);

        items.map( (item, index) => {
            if (index == 0 && item.priority !=null)
                item.priority = 1
            if(index > 0)
            {
                previtem = items[index-1]
                if (previtem.priority != null && item.priority !=null)
                    item.priority = previtem.priority + 1
                if (index == (items.length -1) && previtem.priority !=null)
                item.priority = previtem.priority + 1
            }
        })
        setListItems(items)

    }



    // Loading Logic if tasks not fetched
    if (jobs && jobs.length == 0) {
        return <p> No records </p>
    } else {
        return (
            <Container>
                {jobs && jobs.length == 0 ?
                    <p>Loading ... </p>
                    :
                    <>
                         {/* <div style={{height: (innerHeights-200)+'px', width: "100%"}}> */}
                        <DragDropContext onDragEnd={onDragEnd} >
                            <Table basic='very' className='borderNoneObj' >
                                <TableHeader style={{ width: widths + "px" }}>
                                <Table.Row>
                                <TableHeaderCell colspan={6} style={{ color: "#343A40" , fontWeight: 'normal'}}>
                                    Drag rows to re-organaise priorities.
                                </TableHeaderCell>
                                <TableHeaderCell colspan={1}>
                                        <ViewMenu/>
                                </TableHeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <TableCell style={{ width: '5%' }} className='borderNoneObj head borderBottomObj'>
                                        Priority
                                    </TableCell>
                                    <TableCell style={{ width: '5%' }} className='borderNoneObj head borderBottomObj'>
                                        Status
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} className='borderNoneObj head borderBottomObj'>
                                        Workroom
                                    </TableCell>
                                    <TableCell style={{ width: '40%' }} className='borderNoneObj head borderBottomObj'>
                                        Job
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} className='borderNoneObj head borderBottomObj'>
                                        Deadline
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} className='borderNoneObj head borderBottomObj'>
                                        Date Created
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} className='borderNoneObj head borderBottomObj'>
                                        Created By
                                    </TableCell>
                                    </Table.Row>
                                </TableHeader>
                                <Droppable droppableId={teamId}>
                                    {(provided) => (
                                        <Ref innerRef={provided.innerRef} >
                                            <TableBody
                                            //  style={{height: (innerHeights-20)+'px', width: "100%", 'vertical-align': 'top'}}
                                             style={{width: "100%", 'vertical-align': 'top'}}

                                                {...provided.droppableProps}
                                            >
                                                {listItems.map((job, i) => <JobsTeam job={job} key={job.id} index={i} widths={widths} />)}

                                                {provided.placeholder}
                                            </TableBody>
                                        </Ref>
                                    )}
                                </Droppable>
                            </Table>

                        </DragDropContext>
                        {jobs.length > 1 ?
                        <>
                        <Button type="submit" size="medium" color='blue' className="CustomeBTN" onClick = {handleSubmit}> Submit </Button>
                        <Button type="cancel" size="medium grey" className="CustomeBTN" onClick={handleCancel}> Cancel </Button>
                        </>
                        : null }
                        {savedSuccess ?
                        // <Notification id={savedActivityId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                        <Message positive style = {{width :'50%'}}>
                        <Message.Header>Priorities submitted successfully</Message.Header>
                      </Message>
                        : 
                    null}  
                    </>
                    
                }
            </Container>
        )
    }
}

export default JobsPriorityDndListByTeam;
