import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as select from '../data/selectors'
import { Container } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { fetchJobsByWorkroomId } from '../data/actions'
import { JobTableList } from '../../../utilities/tableUtils'
import moment from 'moment'


const JobsCompletedListByWorkroom = (props) => {
    const workroomId = props.workroomId
    const jobs = useSelector(state => select.getCompletedJobsByWorkroomId(state, workroomId)) 
    const jobsFetched = useSelector(state => select.isJobsFetchedByWorkroomId(state, workroomId))
    const dispatch = useDispatch();

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 

    useEffect(()=>{
    if (!jobsFetched)
        dispatch(fetchJobsByWorkroomId({'workroomId': workroomId}));
    }, [workroomId]);        

    // Loading Logic if tasks not fetched
    if (jobs && jobs.length == 0 ){
        return <p> No records</p>
    }  else {
        return (
            <Container>
            <JobTableList columns={searchColumns} data={jobs} /> 
            </Container>
        )
    }
}

export const searchColumns = [
    {
      Header: 'Sl No',
      accessor: 'Sl No',
    },
    {
      Header: 'Job Status',
      accessor: 'jobStatus',
    },
    {
      Header: 'Job',
      accessor: 'job',
      width: 150
    },
    {
      Header: 'Date Start',
      accessor: 'dtStart', 
      Cell : (props)=>{
        const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
        return <span>{custom_date}</span>
        }
    },
    {
      Header: 'Date Finish',
      accessor: 'dtFinish', 
      Cell : (props)=>{
        const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
        return <span>{custom_date}</span>
        }
    },
    {
        Header: 'Deadline',
        accessor: 'deadline', 
        Cell : (props)=>{
          const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
          return <span>{custom_date}</span>
          }    
    },
    {
      Header: 'Job Owner',
      accessor: 'jobOwner', 
    },
  ]

export default JobsCompletedListByWorkroom;
