import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import moment from 'moment'
import { JobTableList } from '../../../utilities/tableUtils'


const MyTasksCreated = (props) => {
 
    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')

    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 

    const myqueue = props.myjobs

    // Loading Logic if tasks not fetched
    if (myqueue && myqueue.length == 0 ){
        return <p> No records</p>
    }  else {
        return (
            <Container>
            {!myqueue ? 
                <p> No records</p> 
            : 
            <>
            <JobTableList columns={searchColumns} data={myqueue} />
            </>
            }
        </Container>
        )
    }
}

export const searchColumns = [
    {
      Header: 'Sl No',
      accessor: 'Sl No',
    },
    {
      Header: 'Status',
      accessor: 'taskStatus',
    },
    {
      Header: 'Ticket',
      accessor: 'job',
      width: 150
    },
    {
      Header: 'Workroom',
      accessor: 'workroomname', 
    },
    {
      Header: () => (<div style={{textAlign:"right"}}>Priority</div>),
      accessor: 'priority',
      Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>
    },
    {
      Header: 'Deadline',
      accessor: 'deadline', 
      Cell : (props)=>{
        const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
        return <span>{custom_date}</span>
        }    },
    {
      Header: 'Date Start',
      accessor: 'dtStart', 
      Cell : (props)=>{
        const custom_date = props.value ? moment(props.value).format('DD-MM-YYYY') : null
        return <span>{custom_date}</span>
        }
    },
    {
      Header: 'Assigned To',
      accessor: 'assignedTo', 
    },
  ]
export default MyTasksCreated;
