import React from 'react';
import StepsListForPanel from '../../app/components/stepsListForProcessPanel'
import { getProcessFromId } from '../data/selectors';
import { useSelector, useDispatch } from 'react-redux'
import { Link, Redirect} from 'react-router-dom';
import {getActivityByProcessId} from '../../activities/data/selectors'
import {getProcessFileByProcessKey} from '../../processfile/data/selectors'
import {  Grid, Accordion, Button } from 'semantic-ui-react';
// import { useHistory } from "react-router-dom";

const ProcessRightMenu = (props)=> {
  // console.log (' Props is ', props)

  // const history = useHistory();

  const processId = props.location.processId === undefined ? "" : props.location.processId;
  const process = useSelector(state => getProcessFromId(state, processId))
  const activitiesData = useSelector(state => getActivityByProcessId(state, processId))
  // const processfileList = useSelector(state => getProcessFileByProcessKey(state, process.processKey))
  const processfileList = useSelector(state => getProcessFileByProcessKey(state, process.processKey))

  const routeChange = () =>{ 
    console.log (' in route change')
    let path = '/process/processfile/create'

     props.history.push({pathname: path, processId: processId,  processName: process.processName});
  }

  const panelsProcessEdit = [
    {
      key: 'processfile',
      title: { content:
        <span className="padding70">
            <span className="titleHead">
                Process Files    
                    <Button className="buttonCstmWithIcon" color='green' onClick= {routeChange}>
                    +
                  </Button>
            </span>
        </span>
         },
      content: {    
          content: (
            <div >
                <div className="maxMinHeight paddingRight45 marginRightNav15">
                    <StepsListForPanel data = {processfileList} pathToLink='/process/processfile/' processId={processId} displayFields= {['resource', 'version']}/>
                </div>
                
            </div>
          ),
        },
    },
  {
    key: 'activities',
    title: { content:
      <span className="padding70">
          <span className="titleHead">
              Activities
          </span>
      </span>
       },
    content: {    
        content: (
          <div >
              <div className=" paddingRight45 marginRightNav15">
                  <StepsListForPanel isEdit="show" data = {activitiesData} pathToLink='/process/activity/' processId={processId} displayFields= {['activityName']}/>
              </div>
              
          </div>
        ),
      },
  },
  /* {
    key: 'History',
    title: { content:
      <span className="titleHead">
        History
      </span> },
    content: {
      content: (
        <div className="padding40">
            Hello
        </div>
      ),
    },
  }, */
]

return (
    <Grid.Column width={5} className="paddingZero paddingRight30 borderLeft">
        <Accordion className="boxNone" styled panels={panelsProcessEdit} /> 
    </Grid.Column>
  )
}

 export default ProcessRightMenu