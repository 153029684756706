import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Container, Grid, Icon } from "semantic-ui-react";
import PrivateRoute from "./privateRoute";
import { parseJwt } from "../../../utilities/parseJWT";
import AccordioneMenu from "./menuoption";
import MenuDefault from "./menuDefault";
import { getTeamsLedByUser } from "../../users/data/selectors";
import { getUserNameFromId } from "../../users/data/selectors";

import Tasks from "../../tasks/index";
import Users from "../../users/index";
import Process from "../../process/index";
import Jobs from "../../jobs/index";
import Processfile from "../../processfile/index";
import App from "../../app/index";
import Org from "../../organisation/index";
import steps from "../../steps/index";
import WorkRoom from "../../workRoom/index";
import ScheduledProcess from "../../scheduledprocess/index";
import Client from "../../client/index";
import Team from "../../team/index";
import PasswordChangeModal from "./passwordChangeModal";
import Roles from "../../rolesAndPermission/index";
import Module from "../../module/index"

import {
  setMenuHeight,
  widthSet,
  menuAccordionHeight,
  setMainContainerHeight,
} from "../../../utilities/heightForListing";
import { fetchUsers } from "../../users/data/actions";
import { fetchTeam } from "../../team/data/actions";
import { fetchProcess } from "../../process/data/actions";
import { fetchClient } from "../../client/data/actions";
import { fetchWorkroom } from "../../workRoom/data/actions";
import { fetchLastSeen, fetchUnreadCount } from "../../app/data/actions";
import { fetchActivity } from "../../activities/data/actions";
import { requestLogout } from "../../login/data/actions";
import { editLastSeen, setCurrentUser } from "../data/actions";
import { getWorkroomWhereStatusIsOnline } from "../data/selectors";
import { fetchRole } from "../../rolesAndPermission/data/action";
import { fetchModule } from "../../module/data/actions";
import MenuHeader from "./menuHeader";
import configureWebSocket, {
  getStompClient,
} from "../../../store/configureWebSocket";
import userACL from "../../../store/access";

// import '../../../semantic-ui/site/globals/global.css'

const TaskMenu = (props) => {
  const dispatch = useDispatch();

  const [heights, setHeight] = useState("");
  const [mainComtainerHeight, setMainComtainerHeight] = useState("");
  const [accordionHeight, setAccordionHeight] = useState("");
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [menuOption, setMenuOption] = useState("Settings");
  const [menuOptionIcon, setMenuOptionIcon] = useState("database");

  const dateNow = new Date();
  const token = localStorage.getItem("user");
  const header = localStorage.getItem("Header");

  // console.log (' Parsed token is ', parseJwt (token))
  const userId = token ? parseJwt(token).identity : null;
  const teamLed = useSelector((state) => getTeamsLedByUser(state, userId));
  const isValid = localStorage.getItem("exp") > dateNow.getTime() / 1000;
  const logoutDone = useSelector(
    (state) => state.login.params.successfulLogout
  );
  const successfulLogin = useSelector(
    (state) => state.login.params.successfulLogin
  );
  const username = useSelector((state) => getUserNameFromId(state, userId));
  const loggedInWorkRoom = useSelector((state) =>
    getWorkroomWhereStatusIsOnline(state)
  );
  // console.log (' username is ', username)

  useEffect(() => {
    console.log(" userid in menu is ", userId);
    if (userId && isValid) {
      let channel = "/topic/" + userId;
      configureWebSocket(userId, channel);
      dispatch(setCurrentUser(userId));

      dispatch(fetchWorkroom());
      dispatch(fetchTeam());
      dispatch(fetchClient());
      dispatch(fetchUsers());
      dispatch(fetchProcess());
      dispatch(fetchActivity());
      dispatch(fetchLastSeen({ userId }));
      dispatch(fetchUnreadCount({ userId }));
      dispatch(fetchRole())
      dispatch(fetchModule())
      userACL.init({ uid: userId });
    }
  }, [userId, isValid]);

  useEffect(() => {
    if (logoutDone && !successfulLogin) {
      getStompClient().disconnect();
      console.log(" Disconnected ");
      window.location.reload(true);
    }
  }, [logoutDone]);

  useEffect(() => {
    if (props.location.pathname == "/" && isValid && userId) {
      props.history.replace({ pathname: "/app/landing" });
    }
    // document.title = 'RealBooks Tasks'
  }, [props.location.pathname]);

  useEffect(() => {
    setMenuHeight(setHeight);
    menuAccordionHeight(setAccordionHeight);
    setMainContainerHeight(setMainComtainerHeight);
    window.addEventListener(
      "resize",
      function () {
        setMenuHeight(setHeight);
        menuAccordionHeight(setAccordionHeight);
        setMainContainerHeight(setMainComtainerHeight);
      },
      false
    );
  }, []);

  const callMainMenu = () => {
    showMainMenu ? setShowMainMenu(false) : setShowMainMenu(true);
    showMainMenu ? setMenuOption("Settings") : setMenuOption("Exit Settings");
    showMainMenu ? setMenuOptionIcon("database") : setMenuOptionIcon("home");
    props.history.push("/app/landing");
  };

  const callTeamDisplay = () => {
    showMainMenu ? setShowMainMenu(false) : setShowMainMenu(true);
    showMainMenu ? setMenuOption("Settings") : setMenuOption("Exit Settings");
  };

  const logout = () => {
    console.log(" Returning usrid is ", userId);
    console.log(" Workroom where online still shows :", loggedInWorkRoom);

    if (userId && loggedInWorkRoom) {
      dispatch(editLastSeen({ userId: userId, workroomId: loggedInWorkRoom }));
    }
    setTimeout(function () {
      dispatch(requestLogout());
    }, 1500);
  };

  const setHeaderName = (headerName) => {
    localStorage.setItem("Header", headerName);
  };

  // const isValid = true
  // console.log('Menu isValid', isValid, localStorage.getItem('exp'), dateNow.getTime() / 1000);

  if (!token || !isValid) {
    // //console.log("logged in");
    return (
      <Redirect
        to={{
          pathname: "/login",
          // state: { from: location }
        }}
      />
    );
  } else {
    return (
      <Container
        style={{ height: mainComtainerHeight + "px" }}
        className="padding_Ten"
        id="mainContainer"
      >
        <Grid>
          <MenuHeader />

          <Grid.Row className="padding0" style={{ height: heights + "px" }}>
            <Grid.Column
              id="menuCell"
              style={{ height: heights + "px" }}
              mobile={8}
              tablet={4}
              computer={4}
              className="boxBorderRight boxBorderBottom paddingright0 menuCell menuCellWidth"
            >
              <div>
                {showMainMenu ? (
                  <AccordioneMenu
                    userId={userId}
                    accordionHeight={accordionHeight}
                  />
                ) : (
                  <MenuDefault
                    userId={userId}
                    accordionHeight={accordionHeight}
                  />
                )}
              </div>

              <div id="bottomOption" className="  paddingLeft15">
                {teamLed && teamLed.length > 0 && (
                  <div className="paddingLeft15 paddingBottom10 paddingTop10 boxBorderTop ">
                    <div className="paddingTop10">
                      <Icon
                        onClick={callMainMenu}
                        className="pointer "
                        name={menuOptionIcon}
                      />
                      <span
                        onClick={callMainMenu}
                        className=" pointer titleHead fontSize18"
                      >
                        {menuOption}
                      </span>
                    </div>
                  </div>
                )}
                <div className="paddingLeft15 marginTop15 paddingBottom10 paddingTop15 boxBorderBottom">
                  <Icon className="pointer" name="lock" />
                  <PasswordChangeModal userId={userId} />
                </div>
                <div className="paddingLeft15 marginTop15 paddingTop15">
                  <Link to={{ pathname: "/app/landing" }} />
                  <Icon onClick={logout} className="pointer" name="sign-out" />
                  <span
                    onClick={logout}
                    className="fontSize18 pointer titleHead"
                    color="blue"
                  >
                    Sign Out {username}
                  </span>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              id="containerCell"
              mobile={8}
              tablet={12}
              computer={12}
              className="paddingTop20 PaddingLeftZero showYScroll paddingLeft0"
            >
              <Container className="PaddingLeftZero">
                <PrivateRoute path="/process" component={Process} />
                <PrivateRoute path="/processfile" component={Processfile} />
                <PrivateRoute
                  path="/scheduledprocess"
                  component={ScheduledProcess}
                />
                {/* <PrivateRoute path="/activity" component={Activity} /> */}
                <PrivateRoute path="/tasks" component={Tasks} />
                <PrivateRoute path="/jobs" component={Jobs} />

                <PrivateRoute path="/users" component={Users} />
                <PrivateRoute path="/organisation" component={Org} />
                <PrivateRoute path="/steps" component={steps} />
                <PrivateRoute path="/workroom" component={WorkRoom} />
                <PrivateRoute path="/client" component={Client} />
                <PrivateRoute path="/team" component={Team} />
                <PrivateRoute path="/" component={App} />
                <PrivateRoute path="/roles" component={Roles}></PrivateRoute>
                <PrivateRoute path="/module" component={Module}></PrivateRoute>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
};

export default withRouter(TaskMenu);
